.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/*#map_list {
display: flex;
}
#map {
flex:3;
}
#srlist {
    flex: 1;
    overflow: auto;
    height: 400px;
}*/
.container {
    height: 50%;
    width: 100%;
}
#tableDiv {
    height:400px;
    width:100%;
}
.line {
    border-bottom:2px solid #ccc;
    display:block;
}
.display-linebreak {
    white-space: pre-line;
}
.hideElement {
    display:none;
}
.showElement{
    display:block;
}
.btnsContainerRow {
    display: flex;
    justify-content: center;
}

.warningmsg {
color:red;
}

.versiondiv {
    display: block;
    max-width: 1000px;
    margin: auto;
    padding-right:30px;
}
.versionmsg {
    float: right;
    margin-left:5px;
    margin-right:5px;
}
.versionsign {
    float: right;
}
.textarea {
    border: 1px solid #ccc;
    background-color: #e9ecef;
    display: block;
    width: 100%;
    overflow: hidden;
    resize: vertical;
    min-height: 40px;
}
.textarea[contenteditable]:empty::before {
    content: "placeholder";
    background-color: #e9ecef;
}

.textareaEdit {
    border: 1px solid #ccc;
 
    display: block;
    width: 100%;
    overflow: hidden;
    resize:vertical;
  /*  min-height: 40px; */
    height:auto !important;
}

    .textareaEdit[contenteditable]:empty::before {
        content: attr(data-placeholder);
    }

.resizeableText {
    resize: vertical;
    display: block;
    width: 100%;
    overflow: hidden;
}
.pbtype {
    font-size: 20px !important;
    height: 50px !important;
    resize:none !important;
}