html {

    font-family: Open Sans, sans-serif !important;
}

body {
    margin: 0;
    font-family: Open Sans, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(247, 247, 247) !important;
}

.infoTop {
    margin-left: 40px;
}

.infoBottom {
    margin-left: 40px;
}

/* code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} */

.noAllowed {
    cursor: not-allowed !important;
}

.fontIcon {
    margin-left: 5px;
}

.textDecrease {
    height: 15px;
    width: 15px;
}

.textDefault {
    height: 20px;
    width: 20px;
}

.textIncrease {
    height: 25px;
    width: 25px;
}

.maxElement {
    max-width: 1000px;
    margin: auto;
}

.esri-search {
    width: 100% !important;
    border-style: ridge;
}

/* Mobile phones (portrait and landscape) ---------- */
@media screen and (max-width: 767px) {
    .containerFlex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .itemFlex {
        /* flex: 1; */
        text-align: center;
        margin: 2px;
        max-height: 40px !important;
    }

    #overlay {
        display: none;
        position: fixed;
        /*absolute;*/
        width: 100%;
        height: 100%;
        background-color: gray;
        opacity: 0.8;
        z-index: 1;
        top: 0;
        left: 0;
    }

    #screenshotImage {
        height: 100%;
        width: 100%;
    }
}

@media only screen and (min-width: 768px) {
    .containerFlex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .itemFlex {
        flex: 1;
        text-align: center;
        margin: 2px;
    }

    .flex-element {
        flex: 1;
        /* text-align: center;*/
        margin: 2px;
    }

    #overlay {
        display: none;
        position: fixed;
        /*absolute;*/
        width: 100%;
        height: 100%;
        background-color: gray;
        opacity: 0.8;
        z-index: 1;
        top: 0;
        left: 0;
    }

    #screenshotImage {
        height: 200px;
        width: 400px;
    }
}

.form-control-file {
    width: 100%;
    height: 100%;
}

.qa-label {
    color: darkgreen;
    margin: 0px;
    padding: 0px;
}

.qa-input {
    margin-left: 5px;
    /*font-weight: bold;*/
}

.hyperlink {
    float: right;
    color: #2e78ac;
    /*darkblue;*/
    margin-right: 30px;
}

.floatToRight {
    float: right;
}

.homeBtns {
    margin-top: 10px;
}

.clearBtn {
    margin-left: 5px;
}

.floatToLeft {
    float: left;
}

.a-margin {
    margin-left: 50px;
}

.label-font {
    font-weight: bold;
}

.rectangle {
    border-radius: 5px;
    border: 1px solid gray;
    padding: 20px;
    width: 100%;
    min-height: 100px;
    max-height: 300px;
    overflow: auto;
}

.rectangleWarning {
    border-radius: 25px;
    border: 1px solid red;
    padding: 20px;
    width: 100%;
    min-height: 100px;
    padding: 0;
}

.my-alert {
    /* position: -webkit-sticky; 
    position: sticky;*/
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 1;
}



.loadMessage {
    position: relative;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: x-large;
    color: white;
}

.file-input-wrapper {
    margin: 2px;
    overflow: hidden;
    position: relative;
    /* width: 118px;*/
    background-color: transparent;
    cursor: pointer;
    min-height: 250px;
    /* padding-bottom: 50px; */
    border: 2px dashed gray;
    text-align: left;
    font-size: 24px;
    color: rgb(165, 172, 175);
    text-align: left;

}

.file-input-wrapper:hover {
    border: 2px dashed darkgreen;
}
.imageInputClass {
    visibility: hidden;
}

.dragdrop {
   /* margin-top: 20px;*/
    align-items: center;
    display: flex;
    flex-direction: column;
}

#divAddImage{
  flex: 8  !important;
} 
#AddImage:hover {
    background-color: #0082d6 !important;
    border-color: #0082d6 !important;
    border-width: 0px !important;
    border: none !important;
    outline: 0 !important;
    outline-width: 0px !important;
    border: none !important;
    outline: 0 !important;
    outline-width: 0px !important;
    color: white !important;
}
.btnAddImage.itemFlex:hover {
    position: relative !important;
    background-color: transparent !important;
    border-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: .25rem !important;
  }

.file-input-wrapper>input[type="file"] {
    font-size: 40px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
    padding-bottom: 10px;
}


#preview {
    width: 80%;
}

.thumbnail {
    width: 100px;
    margin: 2px;
}

.screenshot_thumbnail {
    width: 400px;
    margin: 200px;
    margin: 0px;
    margin-bottom: 5px;
}

@media (max-width: 500px) {
    .screenshot_thumbnail {
        width: 200px;
        margin: 200px;
        margin: 0px;
        margin-bottom: 5px;
    }
}

.colorred {
    color: gray;
    font-style: italic;
}

.title {
    font-size: 1.5rem;
    font-weight: normal;
    border-bottom: 1px solid gray;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
}

.stack-top {
    z-index: 9;
    position: absolute;
    width: 95%;
    margin-top: 50px;
    opacity: 0.8;
}

.flex-select1 {
    display: flex;
    flex-direction: column;
}

.flex-select {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* border: 1px solid lightgray; */
    /* justify-content: space-between; */
}

.flex-select-elem {
    flex: 1;
    flex-direction: column;
    width: 100%;
    opacity: 0.8;
    color: grey;
    border-color: transparent;
    border: 1px solid transparent;
    padding: 0;
    /* margin: 2px; */
}

.list-group {
    border: 1px solid lightgray;
}

.list-group-item-action {
    border: 1px solid transparent !important;
    padding-bottom: 0 !important;
}

.flex-select-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 300px;
    height: auto;
    overflow: auto;
}

.flex-select-list-item {
    border: 1px solid lightgray;
    padding: 0;
}

.listAddress {
    max-height: 300px;
    height: auto;
    overflow: auto;
}

.selectedData {
    /*border-bottom: 1px solid lightgray;*/
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.cardStyle {
    padding: 20px;
    margin: 20px;
}

button {
    margin: 2px !important;
}

/* .btn-info{
background-color: #2e78ac !important;
} */

/* input[type=radio]::checked {
  background-color: red !important;
} */
.webmap {
    height: 400px;
    background-color: white !important;
}

#_map_list {
    background-color: white !important;
}

.problemClear {
    /* float: right;*/
    margin-left: -50px;
    /*margin-top: -25px;
    margin-right: 10px;
    z-index: 0;*/
}

.version {
    margin: 20px;
}

textarea {
    overflow: inherit;
    min-height: 40px !important;
}

textarea.form-control {
    width: 100%;
    height: 40px;
    min-height: 40px;
}

#problemtype {
    overflow: hidden;

}

li.list-group-item {
    display: flex;
    align-items: center;
}

.QALCard {
    margin-top: 10px;
}

.form-group {
    margin-bottom: 0px !important;
}


.attach_required {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
    display: block;
}
.justify-content-around{
    height: 250px !important;
}